import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './App/App';


ReactDOM.render(
	<>
		<App
			blank='blank'
		/>
	</>,
	document.getElementById('root')
);

